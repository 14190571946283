import Axios from 'axios';
import { apiURL } from './api';

export const reservasAPI = {
    reservar: async (subsecao_id, horario, nome, oab, telefone, email) => {
        let status = {
            status: 0,
            message: '',
        };
        await Axios.post(apiURL + 'reservas/reservar', {
            subsecao_id,
            horario,
            nome,
            oab,
            telefone,
            email
        })
            .then(response => {
                status = response.data;
            })
            .catch(error => {
                status = 0;
                status.message = 'Desculpe, ocorreu um erro na conexão com o servidor. Por favor, tente novamente mais tarde.';
            });
        return status;
    },
}