import Axios from 'axios';
import { apiURL } from './api';

export const horariosAPI = {
    getPorData: async(subsecao_id, data) => {
        let horarios = [];
        await Axios.post(apiURL + 'horarios/disponiveis', {
                subsecao_id,
                data: data,
            })
            .then(response => {
                horarios = response.data;
            })
            .catch(error => {
                alert('Desculpe, ocorreu um erro na conexão com o servidor. Por favor, tente novamente mais tarde.');
            });
        return horarios;
    },
}