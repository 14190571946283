import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, Table, Button, Icon, Input, Row, Col } from 'antd';

import { horariosAPI } from '../services/horarios';
import { reservasAPI } from '../services/reservas';

import moment from 'moment-timezone';
import 'moment/locale/pt-br';

import logoRound100 from '../assets/img/logo_round_100.png';

moment.tz.setDefault("America/Danmarkshavn");

const ReservarSala = () => {
    const { subsecaoId } = useParams();

    const [horarios, setHorarios] = useState();
    const [data, setData] = useState(moment());
    const [dataStr, setDataStr] = useState(moment().format('YYYY-MM-DD'));
    const [nome, setNome] = useState();
    const [oab, setOab] = useState();
    const [telefone, setTelefone] = useState();
    const [email, setEmail] = useState();
    const columns = [{
        title: 'Horário',
        dataIndex: 'legenda',
        key: 'legenda',
    }, {
        title: 'Sala',
        dataIndex: 'sala',
        key: 'sala',
    }, {
        title: '',
        dataIndex: 'acoes',
        key: 'acoes',
    }];

    useEffect(() => {
        setData(moment(dataStr));
        horariosAPI.getPorData(subsecaoId, dataStr)
            .then((horarios => {
                const _horarios = horarios.map((horario) => {
                    horario.key = horario._id;
                    horario.legenda = moment(horario.inicio).format('h[h] [e] mm[min] a').replace(' e 00min', '').replace('am', 'da manhã').replace('pm', moment(horario.inicio).hours() >= 18 ? 'da noite' : 'da tarde');
                    horario.sala = horario.sala.nome;
                    horario.acoes = (
                        <Fragment>
                            <Button.Group>
                                <Button
                                    type="default"
                                    onClick={() => reservar(horario._id)}
                                ><Icon type="clock-circle" /> Reservar</Button>
                            </Button.Group>
                        </Fragment>
                    );
                    return horario;
                });
                setHorarios(_horarios);
            }));
    }, [dataStr, subsecaoId]);

    const reservar = async(horario) => {
        // Validar dados
        if (nome === "" || nome === null) {
            alert("Por favor, preencha o campo nome.");
            return;
        }
        if (oab === "" || oab === null) {
            alert("Por favor, informe o número da OAB.");
            return;
        }
        if (telefone === "" || telefone === null) {
            alert("Por favor, preencha o campo telefone.");
            return;
        }
        if (email === "" || email === null) {
            alert("Por favor, preencha o campo e-mail.");
            return;
        }

        // Efetuar reserva
        reservasAPI.reservar(subsecaoId, horario, nome, oab, telefone, email)
        .then(res => {
            setDataStr(null);
            setDataStr(dataStr);
            alert(res.message);
        });
    }

    const onPanelChange = (val) => {
        setData(val);
        setDataStr(moment(val).format('YYYY-MM-DD'));
    }

    const changeNome = (e) => {
        setNome(e.target.value);
    }
    const changeOAB = (e) => {
        setOab(e.target.value);
    }
    const changeTelefone = (e) => {
        setTelefone(e.target.value);
    }
    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    return (
        <div style={{padding: 20}}>
            <img alt="Daily Subseção" src={logoRound100} style={styles.logo} />
            <h1>Reservar Sala de Reunião</h1>
            <Input
            placeholder="Nome do responsável"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={changeNome}
            size="large"
            style={styles.field}
            />
            <Input
            placeholder="OAB do responsável"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={changeOAB}
            size="large"
            style={styles.field}
            />
            <Input
            placeholder="Telefone do responsável"
            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={changeTelefone}
            size="large"
            style={styles.field}
            />
            <Input
            placeholder="E-mail do responsável"
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            onChange={changeEmail}
            size="large"
            style={styles.field}
            />
            <Row>
                <Col xs={24} lg={12}>
                    <h2>Para qual data deseja reservar?</h2>
                    <Calendar
                    value={data}
                    onSelect={setDataStr}
                    onPanelChange={onPanelChange}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <h2>Horários Disponíveis</h2>
                    {
                        horarios &&
                        <div>
                            { horarios.length ? 
                                <div>
                                    <Table dataSource={horarios} columns={columns} />
                                </div>
                            :
                                <div>
                                    Não há horários disponíveis para este dia.
                                </div>
                            }
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );
}

const styles = {
    logo: {
        float: 'right',
        width: 60,
        height: 60,
        marginBottom: 10,
    },
    field: {
        marginBottom: 8,
    }
}

export default ReservarSala;
