import React, { Component } from 'react';
import Axios from 'axios';

class Agenda extends Component {
    state = {
        eventos: []
    }
    componentDidMount() {
        Axios.get('https://api.dailysubsecao.com.br/eventos').then(res => {
            const eventos = res.data;
            console.log(eventos);
        });
    }

    render() {
        return (
            <div>
                <div style={{
                    width: '100%',
                    minHeight: 80,
                }}>
                    <div style={{
                        width: 100,
                        padding: 10,
                        borderRadius: 20,
                        border: '3px solid #ccc',
                        textAlign: 'center'
                        }}>
                        <span style={{
                            fontSize: 32,
                        }}>20</span><br />
                        <span style={{
                            fontSize: 14,
                        }}>AGO</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Agenda;