import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import Agenda from './pages/Agenda';
import ReservarSala from './pages/ReservarSala';

const AppRouter  = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/agenda" component={Agenda} />
                <Route exact path="/:subsecaoId/reservarsala" component={ReservarSala} />
            </Switch>
        </Router>
    );
}

export default AppRouter;