import axios from 'axios';

// URL da API
export const apiURL = process.env.NODE_ENV === 'production' ?
'https://apiv2.dailysubsecao.com.br/' // Produção
:
'http://127.0.0.1:3001/'; // Desenvolvimento

// Obter token de usuário armazenado
const auth_token = () => {
    const USER_TOKEN = localStorage.getItem('user_token');
    return USER_TOKEN;
}

// API com autenticação
const api = axios.create({
    baseURL: apiURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

api.interceptors.request.use(
    (config) => {
        if (
            !config.url.endsWith('login') ||
            !config.url.endsWith('logout') ||
            !config.url.endsWith('signup')
        ) {
            const token = auth_token();
            if (token) config.headers.Authorization = `JWT ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (!error.config.url.endsWith('login') && error.response && error.response.status === 401) {
        alert(JSON.stringify(error));
        localStorage.clear();
        // NavigationService.navigate('Auth');
        return error;
    }
    return Promise.reject(error);
});

export default api;
